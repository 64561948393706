/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  salesRepPagination: {
    page: 1,
    offset: 0,
    limit: 10,
    total: 0,
    search: "",
    sort: "",
    hasRequest: false,
  },
});

const getters = {
  salesRepPagination: (state) => state.salesRepPagination,
};

const mutations = {
  SET_SALES_REP_PAGINATION(state, payload) {
    Vue.set(state, "salesRepPagination", payload);
  },

  SET_SALES_REP_HAS_REQUEST(state, bool) {
    Vue.set(state.salesRepPagination, "hasRequest", bool);
  },

  CLEAR_PAGINATIONS(state) {
    Vue.set(state, "salesRepPagination", initialState().salesRepPagination);
  },
};

const actions = {
  fetchOmnichannelSummary({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/summary-dashboards/omnichannel${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchVoipSummary({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/summary-dashboards/voip${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchImbotSummary({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/summary-dashboards/imbot${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchTpoSummary({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/summary-dashboards/tpo${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardSummary({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/summary-dashboards/overall${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  ////////////////////////////////////////////////////////////////////////////////////

  fetchDashboard({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/dashboards/${workspaceId}${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardSaleStageStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/sales-stage-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardClosedTypeStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/closed-type-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardStateTypeStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/state-type-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardCampaignStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/campaign-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardSaleMediaStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/media-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardProbabilityStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/probability-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardUserStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/user-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDashboardSourceType({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/source-type-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  ////////////////////////////////////////////////////////////////////////////////////

  fetchTpoStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/commercial/dashboards${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchTaskTypeStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/commercial/dashboards/task-type-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchTaskEndedTypeStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/commercial/dashboards/task-ended-type-stats${params || ""}`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchTmaStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/commercial/dashboards/tma-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchConvertionRate({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/commercial/dashboards/conversion-rate${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchRemoteSalesRate({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/commercial/dashboards/remote-sales-conversion-stats${
            params || ""
          }`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchExternalVisitRate({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/commercial/dashboards/external-visit-conversion-stats${
            params || ""
          }`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchSpontaneousVisitRate({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/commercial/dashboards/spontaneous-visit-conversion-stats${
            params || ""
          }`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchStreetVisitRate({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/commercial/dashboards/street-visit-conversion-stats${
            params || ""
          }`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchGeneralRate({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/commercial/dashboards/conversion-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchEffortTime({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/commercial/dashboards/effort-time${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchDigitalServiceConvertionRate({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/commercial/dashboards/digital-service-conversion-stats${
            params || ""
          }`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchTimeRanking({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/commercial/dashboards/effort-time-details${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },

  fetchSalesRepStats({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/dashboards/sales-rep-stats${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
